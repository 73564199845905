/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-24 16:22:30
 * @Module: 登录
 */
<template>
  <div class="login-right-main">
    <div class="active">
      <div v-for="item in activeList"
           :key="item.key"
           :class="active==item.key?'active-ac':'active-df'"
           @click="activeClick(item)">
        {{item.title}}
        <div class="active-border"
             v-show="active==item.key"></div>
      </div>
    </div>
    <el-form ref="formParm"
             :model="formParm"
             style="margin-top:30px"
             :rules="rules">
      <el-form-item prop="phone"
                    :error="errorMessage?errorMessage.phone:''">
        <el-input v-model="formParm.phone"
                  placeholder="请填写您的手机账号"
                  maxlength="11"></el-input>
      </el-form-item>
      <el-form-item v-show="active=='account'"
                    prop="password"
                    :error="errorMessage?errorMessage.password:''">
        <el-input v-model="formParm.password"
                  placeholder="请输入密码"
                  type="password"
                  @keyup.enter.native="submitForm"></el-input>
      </el-form-item>
      <el-form-item v-show="active=='mobile'"
                    prop="verify_code"
                    :error="errorMessage?errorMessage.verify_code:''">
        <el-input v-model="formParm.verify_code"
                  placeholder="请输入验证码"
                  @keyup.enter.native="submitForm"
                  maxlength="4">
          <el-button type="text"
                     slot="suffix"
                     style="margin-right:16px;"
                     @click="_reqVerifyCode">{{secondrender}}</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary"
               class="btn-style"
               @click="submitForm">登录</el-button>
    <div class="login-bottom-btn">
      <div>
        <el-checkbox v-model="autoLogin">自动登录</el-checkbox>
      </div>
      <div>
        <router-link to="regist">
          <el-button type="text"
                     style="color:#666;">忘记密码</el-button>
        </router-link>
        <el-divider direction="vertical" />
        <router-link to="forget">
          <el-button type="text">注册账户</el-button>
        </router-link>
      </div>
    </div>
    <div class="service-tel">客服电话：400-119-3009</div>
    <img src="../../static/img/login_card_bg2.png"
         class="login_card_bg" />
    <scanCode @success="scanCodeSuccess"
              :formparm="formParm"
              v-if="showScanCode" />
  </div>
</template>
<script>
import scanCode from "./components/scanCode"
export default {
  components: {
    scanCode
  },
  data () {
    return {
      activeList: [
        {
          title: "账号密码登录",
          key: "account",
        },
        {
          title: "验证码登录",
          key: "mobile",
        },
      ],
      active: "account",
      formParm: {
        phone: "",
        password: "",
        verify_code: "",
      },
      autoLogin: true,
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        verify_code: [
          {
            required: false,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      errorMessage: null,
      second: 0,
      showScanCode: false
    };
  },
  computed: {
    secondrender () {
      return this.second == 0 ? "获取验证码" : this.second + "S";
    },
  },
  methods: {
    // 切换登陆方式
    activeClick ({ key }) {
      this.active = key;
      this.rules.password[0].required = key == "account";
      this.rules.verify_code[0].required = key == "mobile";
    },
    // 提交表单
    submitForm () {
      this.errorMessage = null;
      this.$refs.formParm.validate((valid) => {
        if (valid) {
          this._reqLogin();
          // if (this.$isMobile()) {
          // } else {
          //   this.showScanCode = true
          // }
        } else {
          return false;
        }
      });
    },
    // 登录请求
    async _reqLogin () {
      const { formParm, active } = this;
      const { status_code, message } = await this.$api.authLoginApi({
        ...formParm,
        type: active,
      });
      if (status_code === 200) {
        this.$store.commit("user/setUserInfo", message);
        this.$setStorage("userInfo", message);
        this.$setStorage("autoLogin", this.autoLogin);
        if (this.role(["staff"])) {
          this.$router.push({ path: "/anchor/anchorList" });
        } else {
          this.$router.push({ path: "/home" });
        }
      } else if (status_code === 422) {
        this.errorMessage = message;
      } else {
        this.$message({
          message,
          type: "warning",
        });
      }
    },
    //获取验证码
    async _reqVerifyCode () {
      const { phone } = this.formParm;
      if (phone == "") {
        this.errorMessage = {
          phone: "请输入手机号",
        };
        return;
      }
      if (this.second > 0) {
        return;
      }
      const { status_code, message } = await this.$api.verifyCodeApi({
        phone,
      });
      if (status_code === 200) {
        this.$message({
          message: "获取验证码成功",
          type: "success",
        });
        this.second = 60;
        let js = setInterval(() => {
          this.second--;
          if (this.second == 0) {
            clearInterval(js);
          }
        }, 1000);
      } else if (status_code === 422) {
        this.errorMessage = message;
      } else {
        this.$message({
          message,
          type: "warning",
        });
      }
    },
    //验证码成功回调
    scanCodeSuccess (res) {
      this.showScanCode = false
      if (res) {
        this._reqLogin();
      }
    }
  },
};
</script>
<style>
@import "./style.less";
.active {
  display: flex;
}
.active-df,
.active-ac {
  width: 180px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  position: relative;
}
.active-df {
  color: #999;
  border-bottom: 1px solid #eee;
}
.active-ac {
  color: #333;
  font-weight: bold;
}
.active-border {
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, #f98c73 0%, #fa4e5a 100%);
  border-radius: 2px;
  position: absolute;
  bottom: 0px;
}
.login-bottom-btn {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  font: 14px;
}
.service-tel {
  margin-top: 40px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #999;
  font-size: 14px;
}
</style>